import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic11 from '../assets/images/pic11.jpg'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Services</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Services</h1>
                    </header>
                    <p>Listed below are the different services I can provide. For transparency, I have prices listed directly on the website. First sessions are 20% off, even if it is the only session we have.</p>
                    <p>Sessions may only take place online at this time. A typical session will be one to two hours long, but a session lasting three or more hours can be discussed. </p>
                </div>
            </section>
            <section id="two">
                <div className="inner">
                    <h1>One-on-One tutoring</h1>
                    <h2>$50/hour</h2>
                    <p>The most traditional type, I will meet with the tutee and help with math concepts. This can be for a specific class, topic, or prep for the math portion of exams like the SAT/ACT!</p>
                    <ul className="actions vertical">
                        <li><a href="/contact" className="button fit">Schedule a Session</a></li>
                    </ul>
                </div>
            </section>
            <section id="three">
                <div className="inner">
                    <h1>Group tutoring</h1>
                    <h2>$30-40 per tutee/hour</h2>
                    <p>This type involves multiple tutees at the same time and place. I will tutor everyone at once in the math concepts of the group’s choosing. Price depends on the number of tutees, more tutees means a greater discount.</p>
                    <ul className="actions vertical">
                        <li><a href="/contact" className="button fit">Schedule a Session</a></li>
                    </ul>
                </div>
            </section>
            <section id="four">
                <div className="inner">
                    <h1>Study Tips & Tricks</h1>
                    <h2>$50/hour</h2>
                    <p>Every student has a different method of learning that might work better for them than others. There are also some study-informed methods of studying for school. Let me help you by giving you different tips, tricks, and techniques for acing tests & feeling more confident in your knowledge!</p>
                    <ul className="actions vertical">
                        <li><a href="/contact" className="button fit">Schedule a Session</a></li>
                    </ul>
                </div>
            </section>
            <section id="five">
                <div className="inner">
                    <h1>Crash Course Math</h1>
                    <h2>$50-75/hour</h2>
                    <p>Ever wanted to learn a subject but the internet isn’t being helpful? Let me give it a shot! Give me a field of math that you wish you learned better or want to learn more about, and we’ll talk about it in tutoring-style sessions! The first session will include an overview of the number of sessions I think are needed to cover the subject, as well as the subtopics covered in each session. Price is based on the difficulty of the subject.</p>
                    <ul className="actions vertical">
                        <li><a href="/contact" className="button fit">Schedule a Session</a></li>
                    </ul>
                </div>
            </section>
            <section id="six">
                <div className="inner">
                    <h1>Disclaimer</h1>
                    <p>I am not certified as a teacher, professor, or otherwise. My sessions do not count as class credit, credit hours, or hours of learning for homeschooled students. These sessions are simply meant to help you succeed in a subject and become confident in your knowledge.</p>
                </div>
            </section>
        </div>

    </Layout>
)

export default Generic
